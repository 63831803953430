import { Link } from "@chakra-ui/react";

// TODO: Improve the css. Also, this code is very messy.
const CfUserWithColor = ({ handle, title }) => {
  const titleColors = {
    Newbie: "grey",
    Pupil: "green",
    Specialist: "#03A89E",
    Expert: "blue",
    "Candidate Master": "purple",
    Master: "orange",
    "International Master": "orange",
    Grandmaster: "red",
    "International Grandmaster": "red",
    "Legendary Grandmaster": "red",
  };

  const color = titleColors[title];

  if (color) {
    if (title === "Legendary Grandmaster") {
      const firstLetterColor = "black";
      const restOfHandle = handle.slice(1); // Extract the handle except the first letter
      return (
        <>
          <span style={{ color: firstLetterColor }}>{handle[0]}</span>
          <span style={{ color }}>{restOfHandle}</span>
        </>
      );
    } else {
      return <span style={{ color }}>{handle}</span>;
    }
  } else return <>{handle}</>;
};

const CfUser = ({ handle, title }) => {
  return (
    <Link isExternal href={`https://codeforces.com/profile/${handle}`}>
      <span style={{ fontWeight: "bold" }}>
        <CfUserWithColor handle={handle} title={title} />
      </span>
    </Link>
  );
};

export default CfUser;
