import React from "react";
import { Text } from "@chakra-ui/react";

const ParticipantNotFound = () => {
  return (
    <Text>
      This participant was not found. Do they exist? Have they participated in a
      contest?
    </Text>
  );
};

export default ParticipantNotFound;
