import { useState, useEffect } from "react";
import {
  Input,
  Icon,
  Text,
  InputGroup,
  InputLeftElement,
  VStack,
  Box,
  Button,
  InputRightAddon,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CfUser from "./CfUser";
import placeholderUsers from "../resources/placeholderUsers";
import { FaArrowRight } from "react-icons/fa";

const getRndIndex = (arr) => Math.floor(Math.random() * arr.length);

const Search = ({ onSearch }) => {
  const [handle, setHandle] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(
    getRndIndex(placeholderUsers)
  );
  const [isInputFocused, setIsInputFocused] = useState(false);
  // TODO: Move this to a global theme
  const borderRadiusSize = "30";

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex(getRndIndex(placeholderUsers));
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  const handleSearch = async (handleToSearch) => {
    try {
      await onSearch(handleToSearch.trim());
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  const handleClick = () => {
    const handleToSearch =
      handle === "" ? placeholderUsers[placeholderIndex] : handle;
    setHandle(handleToSearch);
    handleSearch(handleToSearch);
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <Box w="100%">
      <VStack
        spacing={2}
        backgroundColor="white"
        padding="0 5px"
        borderRadius={10}
        shadow="0 0 10px 10px white"
      >
        <Text fontSize="xl">Find the shortest path from</Text>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            focusBorderColor="none"
            autoCorrect="off"
            borderRadius={borderRadiusSize}
            type="text"
            value={handle}
            onChange={(e) => setHandle(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch(handle)}
            variant="outline"
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={
              isInputFocused ? "" : placeholderUsers[placeholderIndex]
            }
          />
          <InputRightAddon
            borderRightRadius={borderRadiusSize}
            overflow="hidden"
            p={0}
          >
            <Button
              onClick={handleClick}
              bgColor="gray.700"
              size="md"
              h="100%"
              borderRadius="0"
              aria-label="Search"
            >
              <Icon color="white" as={FaArrowRight} boxSize="5" />
            </Button>
          </InputRightAddon>
        </InputGroup>
        <Text fontSize="xl">
          to <CfUser handle={"tourist"} title={"Legendary Grandmaster"} />
        </Text>
      </VStack>
    </Box>
  );
};

export default Search;
