import Search from "./Search";
import Results from "./Results";
import React, { useState, useEffect } from "react";
import { getPathByHandle } from "../api";
import About from "./About";
import ParticipantNotFound from "./ParticipantNotFound";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "./Loading";

const SearchPanel = () => {
  let { user } = useParams();

  const [path, setPath] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchPath(user);
    } else {
      setPath(null);
      setHasError(false);
      setHasSearched(false);
      setIsLoading(false);
    }
  }, [user]);

  const fetchPath = async (handle) => {
    setPath(null);
    setHasError(false);
    setHasSearched(true);
    setIsLoading(true);
    try {
      const fetchedPath = await getPathByHandle(handle);
      setPath(fetchedPath);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Search onSearch={(handle) => navigate(`/${handle}`)} />
      {isLoading && <Loading />}
      {hasSearched || <About />}
      {path && <Results userData={path} />}
      {hasError && <ParticipantNotFound />}
    </>
  );
};

export default SearchPanel;
