import { Button, Center } from "@chakra-ui/react";
import { toast } from "react-hot-toast";

const ShareButton = ({ handle, degree }) => {
  const degreesAway = `${handle} is ${degree} degree${
    degree === 1 ? "" : "s"
  } away from tourist!`;
  const url = `${window.location.origin}/${handle}`;

  const ShareNatively = async () => {
    try {
      await navigator.share({
        text: degreesAway + "\n",
        title: degreesAway,
        url: url,
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const CopyToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast.success("Link copied to clipboard!");
  };

  const handleClick = navigator.share ? ShareNatively : CopyToClipboard;

  return (
    <Center>
      <Button color="white" bgColor="gray.700" size="lg" onClick={handleClick}>
        Share!
      </Button>
    </Center>
  );
};

export default ShareButton;
