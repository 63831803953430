import { Image, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import sdocLogo from "../images/logo.svg";

const Header = () => {
  const navigate = useNavigate();

  return (
    <VStack spacing={3}>
      <Image
        htmlWidth="400"
        src={sdocLogo}
        alt="Six Degrees of Codeforces"
        onClick={() => navigate("/")}
      />
    </VStack>
  );
};

export default Header;
