import React from "react";
import { Heading, VStack, Text } from "@chakra-ui/react";
import CfUser from "./CfUser";

const About = () => {
  return (
    <VStack
      align="left"
      lineHeight="1.4"
      shadow="0 0 10px 10px white"
      backgroundColor="white"
    >
      <Heading size="md">What is this all about?</Heading>
      <Text>
        Six Degrees of Codeforces is a tool to find the distance between a
        codeforces user and{" "}
        <CfUser handle="tourist" title="Legendary Grandmaster" />, who is widely
        regarded as the best competitive programmer in the world.
      </Text>
      <Text>
        The definition is simple:{" "}
        <CfUser handle="tourist" title="Legendary Grandmaster" /> itself has
        degree 0.
      </Text>
      <Text>
        <CfUser handle="orz" title="Grandmaster" /> is 1 degree away from
        tourist since he placed better in context X.
      </Text>
      <Text>
        I (<CfUser handle="nitor" title="Candidate Master" />) have never
        outperformed <CfUser handle="tourist" title="Legendary Grandmaster" />{" "}
        in a contest - at least not yet! - but I have outperformed{" "}
        <CfUser handle="orz" title="Grandmaster" /> in contest Y. Therefore, my
        degree is 2.
      </Text>
      <Text>That's it! Go check out your and your friends' degrees now!</Text>
    </VStack>
  );
};

export default About;
