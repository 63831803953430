import { API_BASE_URL } from "./resources/constants";

export async function getPathByHandle(handle) {
  const url = `${API_BASE_URL}/path/${handle}/`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch path by handle");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while fetching the path");
  }
}
