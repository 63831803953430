import { Flex, Box, Text, Heading, Center, VStack } from "@chakra-ui/react";
import CfUser from "./CfUser";
import CfContest from "./CfContest";
import degreeMsgs from "../resources/degreeMsgs.json";
import ShareButton from "./ShareButton";

const ContestantRow = ({ user }) => {
  return (
    <Box display="flex" alignItems="baseline" p={1}>
      <Box px={1} w="4.5rem">
        <Text textAlign="center" fontSize="sm" fontWeight="bold">
          #{user.rank}
        </Text>
      </Box>
      <Box>
        <Text fontSize="md">
          <CfUser handle={user.handle} title={user.title} />
        </Text>
      </Box>
    </Box>
  );
};

const ContestCaption = ({ contest }) => {
  return (
    <Center>
      <Box p={2}>
        <Heading
          as="h2"
          fontWeight="normal"
          noOfLines={1}
          fontSize="md"
          textAlign={"center"}
        >
          <CfContest id={contest.id} name={contest.name} />
        </Heading>
      </Box>
    </Center>
  );
};

const ContestTable = ({ data }) => {
  const { contest, user_won, user_lost } = data;
  return (
    <Box p={3} borderWidth="1px" borderRadius="15" backgroundColor="white">
      <ContestCaption contest={contest} />
      <ContestantRow user={user_won} />
      <ContestantRow user={user_lost} />
    </Box>
  );
};

const DegreeCaption = ({ handle, title, degree }) => {
  return (
    <VStack
      textAlign="center"
      shadow="0 0 10px 10px white"
      backgroundColor="white"
    >
      <Text fontSize="xl">
        <CfUser handle={handle} title={title} /> is {degree} degree
        {degree === 1 ? "" : "s"} away from{" "}
        <CfUser handle="tourist" title="Legendary Grandmaster" />!
      </Text>
      <Text fontSize="md" as="i" color="gray.500">
        "{degreeMsgs[degree] || "Wow, that tourist guy is good!"}"
      </Text>
    </VStack>
  );
};

const Results = ({ userData }) => {
  const { handle, title, degree, path } = userData;
  return (
    <>
      <Flex w="100%" gap="4" direction="column">
        <DegreeCaption handle={handle} title={title} degree={degree} />
        <ShareButton handle={handle} degree={degree} />
        {path.map((pathElement, index) => (
          <ContestTable key={index} data={pathElement} />
        ))}
      </Flex>
    </>
  );
};

export default Results;
