import { Text, Spinner } from "@chakra-ui/react";
import cfFacts from "../resources/cfFacts.json";

const Loading = () => {
  // TODO: One random fact per second.
  const randomIndex = Math.floor(Math.random() * cfFacts.length);
  return (
    <>
      <Spinner size="lg" />
      <Text fontSize="xl">{cfFacts[randomIndex]}</Text>
    </>
  );
};

export default Loading;
