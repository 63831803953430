import { Link } from "@chakra-ui/react";

const CfContest = ({ id, name }) => {
  return (
    <Link isExternal href={`https://codeforces.com/contest/${id}/standings`}>
      {name}
    </Link>
  );
};

export default CfContest;
