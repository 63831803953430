// import Footer from "./components/Footer";
import Header from "./components/Header";
import SearchPanel from "./components/SearchPanel";
import {
  ChakraProvider,
  Container,
  VStack,
  Box,
  extendTheme,
} from "@chakra-ui/react";
import GithubCorner from "react-github-corner";
import { Route, Routes } from "react-router-dom";
import "@fontsource/inter/500.css";
import "@fontsource/work-sans/500.css";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { particleConfig } from "./resources/particleConfig";
import { Toaster } from "react-hot-toast";

const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
});

function App() {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box zIndex="-1" position="absolute">
        <Particles init={particlesInit} options={particleConfig} />
      </Box>
      <Toaster />
      <Container maxW="xl" height="100%" minHeight="100%">
        <VStack spacing={10} p={10}>
          <Header />
          {/* <SearchPanel /> */}
          {/* TODO: Bring footer back */}
          {/* <Footer /> */}
          <Routes>
            <Route exact path="/" element={<SearchPanel />} />
            <Route path="/:user" element={<SearchPanel />} />
          </Routes>
          <GithubCorner
            size={70}
            href="https://github.com/diksown/sdoc"
            target="_blank"
            rel="noopener"
          />
        </VStack>
      </Container>
    </ChakraProvider>
  );
}

export default App;
